







import Vue from "vue";
declare const tableau: any;
export default Vue.extend({
  name: "Analysis",
  data() {
    return {
      url: "https://public.tableau.com/views/HCMS-Analytics/SupplyDemandAnalysis",
      options: {}
    };
  },
  mounted: function () {
    this.initViz();
  },
  methods: {
    initViz: function () {
      // eslint-disable-next-line no-undef
      let viz = new tableau.Viz(this.$refs.tableau, this.url, this.options);
      console.log(viz);
    }
  }
});
